import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useRef,
} from 'react'

import Dropdown from '@components/core/forms/dropdown'
import { PortalIcon } from '@components/core/icons'
import BurgerIcon from '@components/core/icons/burger'
import Spinner from '@components/core/spinner'
import {
  SidebarButton,
  SidebarFooter,
  SidebarFooterLabel,
  SidebarFooterValue,
  SidebarLinkList,
  SidebarLinkListItem,
  SidebarLinks,
  SidebarLinksBox,
  SidebarTitle,
  SidebarWrapper,
  SpinnerContainer,
} from '@components/navigation/style'
import { useEnvironment } from '@context/environment'
import { useUser } from '@context/user'
import { NavItemClick } from '@lib/analytics'
import api from '@lib/api'
import { BillingPlanName } from '@lib/billing'
import { removeAllCookies, setEnvironmentIdCookie } from '@lib/cookies'

interface NavigationComponentProps {
  activeMenu: boolean
  toggleActiveMenu: Dispatch<SetStateAction<boolean>>
}

const NavigationComponent: FunctionComponent<NavigationComponentProps> = ({
  activeMenu,
  toggleActiveMenu,
}) => {
  // Derive hooks.
  const router = useRouter()
  const { environment, setEnvironment } = useEnvironment()
  const { user } = useUser()

  // Derive refs.
  const sidebarLinksRef = useRef<HTMLUListElement>(null)

  const scrollToElement = (e: Event) => {
    const target = e.target as HTMLAnchorElement
    const { href: url } = target

    if (url.includes('#') && url.includes(router.asPath)) {
      e.preventDefault()

      const anchor = `${url.split(`#`).pop()}`
      const element = document.getElementById(anchor)

      window.scrollTo({
        top: element.offsetTop - 100,
        behavior: 'smooth',
      })

      return false
    } else if (url.includes(router.asPath)) {
      e.preventDefault()

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })

      return false
    }
  }

  const onLogout = async () => {
    // Call the logout endpoint and remove cookie.
    try {
      await api.auth.logout()
    } catch (error) {
      console.error('Error logging out but removing cookie anyway', error)
    } finally {
      removeAllCookies()
    }

    // Track the event.
    analytics.track(NavItemClick, {
      environmentId: environment.id,
      environmentName: environment.name,
      path: '/logout',
    })

    // Navigate to the login page.
    await router.push('/login')
  }

  const onEnvironmentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target

    const environment = user.custodian.environments.find(
      (env) => env.id === value,
    )

    if (environment) {
      // Update the environment context.
      setEnvironment(environment)

      // Update the environment cookie.
      setEnvironmentIdCookie(environment.id)
    }
  }

  // Handle nav item clicks.
  const onNavItemClick = (path: string) => () => {
    // Track the event.
    analytics.track(NavItemClick, {
      environmentId: environment.id,
      environmentName: environment.name,
      path,
    })
  }

  // Add scroll behavior for local page links
  useEffect(() => {
    if (sidebarLinksRef.current) {
      const links = sidebarLinksRef.current?.getElementsByTagName('a')
      if (links) {
        Array.from(links).forEach((link: HTMLAnchorElement) => {
          link.addEventListener('click', scrollToElement)
        })
      }

      // Cleanup event listeners on component unmount
      return () => {
        if (links) {
          Array.from(links).forEach((link: HTMLAnchorElement) => {
            link.removeEventListener('click', scrollToElement)
          })
        }
      }
    }
  }, [sidebarLinksRef])

  return (
    <SidebarWrapper className="sidebar-wrapper">
      <SidebarTitle>
        <PortalIcon id="logo" />
        <SidebarButton
          onClick={() => toggleActiveMenu(!activeMenu)}
          className="sidebar-button"
        >
          <BurgerIcon className="burger" />
        </SidebarButton>
      </SidebarTitle>

      <SidebarLinksBox className="sidebar-links-box">
        {user && environment ? (
          <SidebarLinks>
            <SidebarLinkList>
              <SidebarLinkListItem active={router.route === '/dashboard'}>
                <Link passHref legacyBehavior href="/dashboard">
                  <a id="nav-item-home" onClick={onNavItemClick('/dashboard')}>
                    Home
                  </a>
                </Link>
              </SidebarLinkListItem>

              <li className="nav-section-title">WALLET MANAGEMENT</li>

              {/* <SidebarLinkListItem active={router.route === '/wallets'}>
              <Link passHref legacyBehavior href="/wallets">
                <a
                  id="nav-item-wallets"
                  onClick={onNavItemClick('/wallets')}
                >
                  Wallets
                </a>
              </Link>
            </SidebarLinkListItem> */}

              <SidebarLinkListItem active={router.route === '/operations'}>
                <Link passHref legacyBehavior href="/operations">
                  <a
                    id="nav-item-operations"
                    onClick={onNavItemClick('/operations')}
                  >
                    Operations
                  </a>
                </Link>
              </SidebarLinkListItem>

              <SidebarLinkListItem active={router.route === '/faucets'}>
                <Link passHref legacyBehavior href="/faucets">
                  <a id="nav-item-faucets" onClick={onNavItemClick('/faucets')}>
                    Faucets
                  </a>
                </Link>
              </SidebarLinkListItem>

              <li className="nav-section-title">CONFIGURATION</li>

              <SidebarLinkListItem
                active={
                  router.asPath === '/configuration#custodian-api-keys' ||
                  router.asPath === '/configuration#client-api-keys'
                }
              >
                <Link
                  passHref
                  legacyBehavior
                  href="/configuration#custodian-api-keys"
                >
                  <a
                    id="nav-item-api-keys"
                    onClick={onNavItemClick(
                      '/configuration#custodian-api-keys',
                    )}
                  >
                    API Keys
                  </a>
                </Link>
              </SidebarLinkListItem>

              {!environment?.backupWithPortalEnabled && (
                <SidebarLinkListItem
                  active={router.asPath === '/configuration#webhook'}
                >
                  <Link passHref legacyBehavior href="/configuration#webhook">
                    <a
                      id="nav-item-webhooks"
                      onClick={onNavItemClick('/configuration#webhook')}
                    >
                      Webhooks
                    </a>
                  </Link>
                </SidebarLinkListItem>
              )}

              <SidebarLinkListItem
                active={router.asPath === '/configuration#wc-metadata'}
              >
                <Link passHref legacyBehavior href="/configuration#wc-metadata">
                  <a
                    id="nav-item-walletconnect"
                    onClick={onNavItemClick('/configuration#wc-metadata')}
                  >
                    Wallet Connect
                  </a>
                </Link>
              </SidebarLinkListItem>

              <SidebarLinkListItem
                active={router.asPath === '/configuration#security'}
              >
                <Link passHref legacyBehavior href="/configuration#security">
                  <a
                    id="nav-item-security"
                    onClick={onNavItemClick('/configuration#security')}
                  >
                    Security Scanning
                  </a>
                </Link>
              </SidebarLinkListItem>

              {user?.custodian?.isAccountAbstractionEnabled && (
                <SidebarLinkListItem
                  active={router.asPath === '/configuration#aa'}
                >
                  <Link passHref legacyBehavior href="/configuration#aa">
                    <a
                      id="nav-item-aa"
                      onClick={onNavItemClick('/configuration#aa')}
                    >
                      Gas Subsidization
                    </a>
                  </Link>
                </SidebarLinkListItem>
              )}

              <SidebarLinkListItem active={router.route === '/firewall'}>
                <Link passHref legacyBehavior href="/firewall">
                  <a
                    id="nav-item-firewall"
                    onClick={onNavItemClick('/firewall')}
                  >
                    Firewall
                  </a>
                </Link>
              </SidebarLinkListItem>

              <li className="nav-section-title">SETTINGS</li>

              {/* Profile */}
              <SidebarLinkListItem
                active={router.asPath === '/settings#profile'}
              >
                <Link passHref legacyBehavior href="/settings#profile">
                  <a
                    id="nav-item-profile"
                    onClick={onNavItemClick('/settings#profile')}
                  >
                    Profile
                  </a>
                </Link>
              </SidebarLinkListItem>

              {/* Organization */}
              <SidebarLinkListItem
                active={router.asPath === '/settings#organization'}
              >
                <Link passHref legacyBehavior href="/settings#organization">
                  <a
                    id="nav-item-organization"
                    onClick={onNavItemClick('/settings#organization')}
                  >
                    Organization
                  </a>
                </Link>
              </SidebarLinkListItem>

              {/* Billing */}
              <SidebarLinkListItem
                active={router.asPath === '/settings#billing'}
              >
                <Link passHref legacyBehavior href="/settings#billing">
                  <a
                    id="nav-item-billing"
                    onClick={onNavItemClick('/settings#billing')}
                  >
                    Billing
                  </a>
                </Link>
              </SidebarLinkListItem>

              {/* Team Members */}
              <SidebarLinkListItem active={router.asPath === '/settings#team'}>
                <Link passHref legacyBehavior href="/settings#team">
                  <a
                    id="nav-item-team"
                    onClick={onNavItemClick('/settings#team')}
                  >
                    Team Members
                  </a>
                </Link>
              </SidebarLinkListItem>

              <SidebarLinkListItem
                active={router.asPath === '/settings#single-sign-on'}
              >
                <Link passHref legacyBehavior href="/settings#single-sign-on">
                  <a
                    id="nav-item-sso"
                    onClick={onNavItemClick('/settings#single-sign-on')}
                  >
                    Single Sign-On
                  </a>
                </Link>
              </SidebarLinkListItem>

              <SidebarLinkListItem>
                <button
                  className="logout"
                  id="nav-item-logout"
                  onClick={onLogout}
                >
                  Sign Out
                </button>
              </SidebarLinkListItem>
            </SidebarLinkList>
          </SidebarLinks>
        ) : (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}

        <SidebarFooter>
          <SidebarFooterLabel>Environment</SidebarFooterLabel>
          <SidebarFooterValue>
            <Dropdown
              id="nav-environment-dropdown"
              options={
                user?.custodian?.environments?.map((env) => ({
                  label: env.name,
                  value: env.id,
                  disabled:
                    user?.custodian?.billingPlan?.name ===
                      BillingPlanName.Developer && env.name === 'Production',
                })) || []
              }
              onChange={onEnvironmentChange}
              value={environment?.id}
            />
          </SidebarFooterValue>
        </SidebarFooter>
      </SidebarLinksBox>
    </SidebarWrapper>
  )
}

export default NavigationComponent
