import { FunctionComponent, useState } from 'react'

import Auth from '@components/auth'
import Navigation from '@components/navigation'
import { useEnvironment } from '@context/environment'

import {
  ContentWrapper,
  DashboardContentAlert,
  DashboardContentAlertEmpty,
  DashboardContentWrapper,
  DashboardWrapper,
} from './style'

const Page: FunctionComponent<PageComponentProps> = ({
  children,
  isAuthed = false,
  isDashboard = false,
}) => {
  // Derive hooks
  const { environment } = useEnvironment()

  // Derive state.
  const [isActiveMenu, setIsActiveMenu] = useState<boolean>(false)

  return (
    <Auth isAuthed={isAuthed}>
      <ContentWrapper
        className={`${isActiveMenu ? 'menu-active' : ''}`}
        isDashboard={isDashboard}
      >
        {isDashboard ? (
          <DashboardWrapper>
            <Navigation
              activeMenu={isActiveMenu}
              toggleActiveMenu={setIsActiveMenu}
            />
            <DashboardContentWrapper>
              {environment?.name === 'Development' ? (
                <DashboardContentAlert id="environment-development-banner">
                  <p>
                    You are using your <strong>Development</strong> environment.
                  </p>
                </DashboardContentAlert>
              ) : (
                <DashboardContentAlertEmpty />
              )}
              {children}
            </DashboardContentWrapper>
          </DashboardWrapper>
        ) : (
          <>{children}</>
        )}
      </ContentWrapper>
    </Auth>
  )
}

export default Page
