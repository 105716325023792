import styled from '@emotion/styled'

export const SidebarWrapper = styled.aside(
  {
    display: 'flex',
    flex: '0 0 265px',
    position: 'relative',
  },
  ({ theme }) => ({
    backgroundColor: theme.isDark ? theme.colors.black : null,
    borderRight: `1px solid ${
      theme.isDark ? theme.colors.border.light : theme.colors.border.default
    }`,
  }),
)

export const SidebarLinksBox = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  justifyContent: 'space-between',
  minHeight: 'calc(25vh)',
  maxHeight: 'calc(100vh - 82px)',
  marginTop: '82px',
  overflowY: 'auto',
  width: '100%',
  position: 'relative',

  '@media (max-width: 950px)': {
    backgroundColor: theme.isDark ? theme.colors.black : theme.colors.white,
    boxShadow:
      '0 6px 10px rgba(' +
      (theme.isDark ? '255, 255, 255' : '0, 0, 0') +
      ', 0.25)',
    display: 'none',
    marginTop: 0,
    height: 'calc(100vh - 50px)',
    padding: '16px',
    position: 'fixed',
    right: 0,
    top: '50px',
    zIndex: 6,
  },
}))

export const SpinnerContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflowY: 'auto',
  paddingBottom: '80px',
  paddingTop: '20px',
})

export const SidebarLinks = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflowY: 'auto',
  paddingBottom: '80px',
  paddingTop: '20px',
})

export const SidebarTitle = styled.div(({ theme }) => ({
  padding: '0 16px',
  position: 'absolute',
  left: 0,
  right: 0,
  top: '25px',
  svg: {
    width: 108,
  },

  '@media (max-width: 950px)': {
    alignItems: 'center',
    backgroundColor: theme.isDark ? theme.colors.black : theme.colors.white,
    boxShadow:
      '0 0 10px rgba(' +
      (theme.isDark ? '255, 255, 255' : '0, 0, 0') +
      ', 0.20)',
    display: 'flex',
    height: '50px',
    position: 'fixed',
    top: 0,
    zIndex: 6,
  },
}))

export const SidebarButton = styled.button(({ theme }) => ({
  alignItems: 'center',
  background: theme.colors.backgrounds.greyLight,
  borderRadius: '50%',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.20)',
  display: 'none',
  height: '30px',
  justifyContent: 'center',
  padding: '0',
  position: 'absolute',
  right: '-15px',
  top: '35px',
  width: '30px',

  '@media (max-width: 950px)': {
    right: '16px',
    top: '9px',

    display: 'flex',

    '.burger': {
      display: 'block',
    },
  },

  svg: {
    width: 108,
    g: {
      fill: theme.colors.text.button,
      stroke: theme.colors.text.button,
    },
  },
}))

export const SidebarLinkList = styled.ul(
  {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,

    '&.sub-list': {
      margin: '0 0 0 30px',

      a: {
        fontSize: '14px',
        fontWeight: 'normal',
        padding: '8px 16px 8px 12px',
        transition: 'color 0.3s ease',
      },

      '.logout': {
        background: 'transparent',
        border: 'none',
        fontSize: '14px',
        fontWeight: 'normal',
        margin: 0,
        padding: '8px 16px',

        '&:hover': {
          background: 'transparent',
        },
      },
    },

    '@media (max-width: 950px)': {
      marginTop: 0,
    },
  },
  ({ theme }) => ({
    '&.sub-list': {
      'li.active': {
        borderTopLeftRadius: theme.borderRadius.default,
        borderBottomLeftRadius: theme.borderRadius.default,
        overflow: 'hidden',
      },
    },
    '.nav-section-title': {
      color: theme.colors.text.breadcrumb,
      fontSize: '12px',
      fontWeight: 'bold',
      padding: '24px 16px 8px',
      listStyle: 'none',
      textTransform: 'uppercase',
    },
  }),
)

export const SidebarLinkListItem = styled.li<SidebarLinkListItemProps>(
  {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    zIndex: 0,

    'a, button': {
      alignItems: 'center',
      display: 'flex',
      fontWeight: 'bold',
      padding: '10px 16px',
      textDecoration: 'none',

      svg: {
        marginRight: '8px',
        minWidth: '18px',
      },
    },

    button: {
      background: 'none',
      width: '100%',
    },

    '.pr': {
      position: 'relative',
    },

    '.arrow': {
      bottom: 0,
      margin: 'auto 0',
      position: 'absolute',
      right: '10px',
      top: 0,
      width: '12px',
      transition: 'transform 0.3s ease',
    },

    '.active .arrow': {
      transform: 'rotate(180deg)',
    },
  },
  ({ active = false, theme }) => ({
    color: theme.colors.text.navigation,

    '&.active a, &.active button': {
      background: theme.colors.backgrounds.primaryLight,
      color: theme.colors.primary,
    },

    'a, button': {
      background: active
        ? theme.colors.backgrounds.primaryLight
        : 'transparent',
      color: active ? theme.colors.primary : theme.colors.text.navigation,

      '&:hover, &.highlighted': {
        color: theme.colors.primary,
      },
    },

    '.logout': {
      color: theme.colors.text.red,
      transition: 'color 0.3s ease',

      '&:hover': {
        color: theme.colors.text.redDark,
      },
    },
  }),
)

export const SidebarFooter = styled.div(({ theme }) => ({
  color: theme.colors.text.navigation,
  display: 'flex',
  flexDirection: 'column',
  padding: '15px',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background: theme.isDark ? theme.colors.black : 'white',
  borderTop: `1px solid ${theme.colors.border.default}`,
  p: {
    margin: 0,
  },
  '@media (max-width: 950px)': {
    marginTop: '16px',
    paddingTop: '16px',
  },
}))

export const SidebarFooterLabel = styled.p(
  {
    alignItems: 'center',
    display: 'flex',
    fontSize: '0.8rem',
    justifyContent: 'space-between',
    margin: 0,
    padding: '0 0 5px 12px',

    a: {
      fontSize: '0.7rem',
      textDecoration: 'none',
    },
  },
  ({ theme }) => ({
    color: theme.colors.text.breadcrumb,

    a: {
      color: theme.colors.primary,
    },
  }),
)

export const SidebarFooterValue = styled.p(
  {
    margin: 0,

    '.dropdown': {
      width: '100%',
    },
  },
  ({ theme }) => ({
    color: theme.colors.text.headline,
  }),
)
